/* See: https://github.com/twbs/bootstrap/blob/v4.6.1/dist/css/bootstrap.css */

body {
  font-size: 14px;
}

html [type='button'] {
  -webkit-appearance: none;
}

input {
  accent-color: #007eb5;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #6c757d;
  border-color: #6c757d;
}

a {
  color: #007eb5;
}

.alert {
  border: none;
}

.alert-primary {
  background: #007eb5;
  background-color: #d2e8f2;
}

.alert-secondary {
  background: #e4e4e4;
  color: #666666;
}

.alert-success {
  background: #d8e9d2;
  color: #217e00;
}

.alert-error {
  background: #fbd2d2;
  color: #e60000;
}

.alert-warning {
  background: #fff6e4;
  color: #856404;
}

.alert-info {
  background: #007eb5;
  background-color: #d2e8f2;
}

.alert-info .alert-link {
  color: #002752;
}

.alert-light {
  background: #fefefe;
  color: #666666;
}

.alert-dark {
  background: #d2d2d2;
  color: #000000;
}

.alert-flash {
  z-index: 999999;
}

.badge-primary {
  color: #fff;
  background-color: #007eb5;
}

.badge-secondary {
  color: #fff;
  background-color: #666666;
}

.badge-success {
  background: #217e00;
  color: #fff;
}

.badge-error {
  background: #e60000;
  color: #fff;
}

.badge-warning {
  background: #ffcc66;
  color: #000;
}

.badge-info {
  color: #fff;
  background-color: #59abcf;
}

.badge-light {
  background: #f5f5f5;
  color: #000;
}

.badge-dark {
  background: #000;
  color: #fff;
}

.btn-link {
  color: #007eb5;
}

.btn-primary {
  background: #007eb5;
  color: #ffffff;
  border-color: #007eb5;
}

.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #006490;
  border-color: #006490;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007eb5;
  border-color: #007eb5;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #007eb5;
  border-color: #000;
}

.btn-outline-primary {
  color: #007eb5;
  background-color: transparent;
  background-image: none;
  border-color: #007eb5;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background: #007eb5;
  border-color: #007eb5;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007eb5;
  background-color: transparent;
  background-image: none;
  border-color: #007eb5;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #007eb5;
  background-color: transparent;
  background-image: none;
  border-color: #007eb5;
}

/* .btn-secondary {
  background: #dddddd;
  color: #666666;
  border-color: #dddddd;
}

.btn-secondary:hover {
  background: #bbbbbb;
  color: #555555;
  border-color: #bbbbbb;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background: #dddddd;
  color: #666666;
  border-color: #dddddd;
} */

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active {
  background: #dddddd;
  color: #666666;
  border-color: #dddddd;
}

.outline-primary,
.outline-primary:hover {
  color: #007eb5;
}

.bg-primary {
  background-color: #007eb5 !important;
}

.bg-info {
  background-color: #007eb5 !important;
}

.text-primary {
  color: #007eb5 !important;
}

.text-info {
  color: #007eb5 !important;
}

.border-primary {
  border-color: #007eb5 !important;
}

.border-info {
  border-color: #007eb5 !important;
}

.btn-link,
.btn-link:active,
.btn-link:hover,
.btn-link:visited {
  text-decoration: none;
}

.v-align-middle-table td {
  vertical-align: middle;
}

.pagination .previous:not(.disabled) .page-link,
.pagination .next:not(.disabled) .page-link,
.pagination .page-item:not(.active) .page-link {
  color: #007eb5;
}
.pagination .page-item.active .page-link {
  background-color: #007eb5;
  border-color: #007eb5;
}

/* Gap utilities (from Bootstrap 5) */
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 1rem !important;
}
.gap-4 {
  gap: 1.5rem !important;
}
.gap-5 {
  gap: 3rem !important;
}

/* Column gap utilities */
.column-gap-0 {
  column-gap: 0 !important;
}
.column-gap-1 {
  column-gap: 0.25rem !important;
}
.column-gap-2 {
  column-gap: 0.5rem !important;
}
.column-gap-3 {
  column-gap: 1rem !important;
}
.column-gap-4 {
  column-gap: 1.5rem !important;
}
.column-gap-5 {
  column-gap: 3rem !important;
}

/* Row gap utilities */
.row-gap-0 {
  row-gap: 0 !important;
}
.row-gap-1 {
  row-gap: 0.25rem !important;
}
.row-gap-2 {
  row-gap: 0.5rem !important;
}
.row-gap-3 {
  row-gap: 1rem !important;
}
.row-gap-4 {
  row-gap: 1.5rem !important;
}
.row-gap-5 {
  row-gap: 3rem !important;
}
