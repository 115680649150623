body {
  font-size: 14px;
}

html [type="button"] {
  -webkit-appearance: none;
}

input {
  accent-color: #007eb5;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #6c757d;
  border-color: #6c757d;
}

a {
  color: #007eb5;
}

.alert {
  border: none;
}

.alert-primary {
  background: #d2e8f2;
}

.alert-secondary {
  color: #666;
  background: #e4e4e4;
}

.alert-success {
  color: #217e00;
  background: #d8e9d2;
}

.alert-error {
  color: #e60000;
  background: #fbd2d2;
}

.alert-warning {
  color: #856404;
  background: #fff6e4;
}

.alert-info {
  background: #d2e8f2;
}

.alert-info .alert-link {
  color: #002752;
}

.alert-light {
  color: #666;
  background: #fefefe;
}

.alert-dark {
  color: #000;
  background: #d2d2d2;
}

.alert-flash {
  z-index: 999999;
}

.badge-primary {
  color: #fff;
  background-color: #007eb5;
}

.badge-secondary {
  color: #fff;
  background-color: #666;
}

.badge-success {
  color: #fff;
  background: #217e00;
}

.badge-error {
  color: #fff;
  background: #e60000;
}

.badge-warning {
  color: #000;
  background: #fc6;
}

.badge-info {
  color: #fff;
  background-color: #59abcf;
}

.badge-light {
  color: #000;
  background: #f5f5f5;
}

.badge-dark {
  color: #fff;
  background: #000;
}

.btn-link {
  color: #007eb5;
}

.btn-primary {
  color: #fff;
  background: #007eb5;
  border-color: #007eb5;
}

.btn-primary:focus, .btn-primary:hover {
  color: #fff;
  background-color: #006490;
  border-color: #006490;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007eb5;
  border-color: #007eb5;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #007eb5;
  border-color: #000;
}

.btn-outline-primary {
  color: #007eb5;
  background-color: #0000;
  background-image: none;
  border-color: #007eb5;
}

.btn-outline-primary:hover {
  color: #fff;
  background: #007eb5;
  border-color: #007eb5;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #007eb5;
  background-color: #0000;
  background-image: none;
  border-color: #007eb5;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  color: #666;
  background: #ddd;
  border-color: #ddd;
}

.outline-primary, .outline-primary:hover {
  color: #007eb5;
}

.bg-primary, .bg-info {
  background-color: #007eb5 !important;
}

.text-primary, .text-info {
  color: #007eb5 !important;
}

.border-primary, .border-info {
  border-color: #007eb5 !important;
}

.btn-link, .btn-link:active, .btn-link:hover, .btn-link:visited {
  text-decoration: none;
}

.v-align-middle-table td {
  vertical-align: middle;
}

.pagination .previous:not(.disabled) .page-link, .pagination .next:not(.disabled) .page-link, .pagination .page-item:not(.active) .page-link {
  color: #007eb5;
}

.pagination .page-item.active .page-link {
  background-color: #007eb5;
  border-color: #007eb5;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

/*# sourceMappingURL=bootstrap4_overrides.12dad1ab.css.map */
